import React, { useEffect, useState, Suspense } from "react";
import { FormData, MainPageData } from "../../types/types";
import api from "../../api/apiContact";

import Options from "../../components/Options/Options";
import Connect from "../../components/Connect/Connect";
import Solutions from "../../components/Solutions/Solutions";
import Benefits from "../../components/Benefits/Benefits";
import Gaming from "../../components/Gaming/Gaming";
import ListConect from "../../components/ListConect/ListConect";
import Started from "../../components/Started/Started";
import Features from "../../components/Features/Features";
import Modal from "../../components/Modal/Modal";
import LatamPopup from "../../components/LatamPopup/LatamPopup";
import Button from "../../components/Button/Button";
import CookiePopup from "../../components/CookiePopup/CookiePopup";
import Partners from "components/Partners/Partners";

const Latam: React.FC = () => {
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [pageData, setPageData] = useState<MainPageData>();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleSubmit = (formData: FormData) => {
    console.log(formData);
    setIsOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await api.getMainPageData("es-AR");
        console.log(data);

        setPageData(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {pageData && (
        <>
          {pageData.connect && (
            <>
              <Connect
                data={pageData.connect}
                openModal={openModal}
                locale="latam"
              />
              <ListConect cards={pageData.connect.cards} />
            </>
          )}
          {pageData.options && <Options data={pageData.options} />}
          {pageData.solutions && (
            <Solutions data={pageData.solutions} openModal={openModal} />
          )}
          {pageData.advantages && (
            <Benefits data={pageData.advantages} openModal={openModal} />
          )}
          {pageData.features && <Features data={pageData.features} />}
          {pageData.empowering && (
            <Gaming data={pageData.empowering} openModal={openModal} />
          )}
          {pageData.getStarted && (
            <Started data={pageData.getStarted} openModal={openModal} />
          )}

          {pageData.partners && <Partners data={pageData.partners} />}

          {isRequestSent ? (
            <Modal
              isOpen={isRequestSent}
              onClose={() => setIsRequestSent(false)}
              className="thank-popup popup"
              title="Thank you!"
            >
              <p className="popup__text text-large ">
                Your request has been successfully sent.
              </p>
              <p className="popup__text text-large ">
                Our expert will contact you soon.
              </p>
              <Button
                className="popup__button"
                type="button"
                onClick={() => setIsRequestSent(false)}
              >
                Close
              </Button>
            </Modal>
          ) : (
            <Modal
              isOpen={isOpen}
              onClose={closeModal}
              className="popup"
              title="Share your request with our sales team"
            >
              <LatamPopup
                className="form-popup"
                onSubmit={handleSubmit}
                setIsOpen={setIsOpen}
                setIsRequestSent={setIsRequestSent}
                locale="es-AR"
              />
            </Modal>
          )}
          <CookiePopup className="cookie" />
        </>
      )}
    </Suspense>
  );
};

export default Latam;
