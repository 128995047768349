import React, { lazy } from "react";

import { motion } from "framer-motion";
// import FormEmail from "../FormEmail/FormEmail";
import Button from "components/Button/Button";
import Globe from "./Globe/Globe";

import { SectionsProps, ConnectData } from "../../types/types";

const Connect = ({
  openModal,
  data,
  locale,
}: { data: ConnectData } & SectionsProps) => {
  return (
    <motion.section
      className="connect"
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="connect__container">
        <div className="connect__info">
          <div className="connect__inner">
            {locale ? (
              <motion.h1
                className="connect__title title color"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.6 }}
              >
                {data.title}
                <motion.span
                  className="title"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 0.9 }}
                >
                  {data.titleColored}
                </motion.span>
              </motion.h1>
            ) : (
              <motion.h1
                className="connect__title title "
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.6 }}
              >
                {data.title}
                <motion.span
                  className="title color"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 0.9 }}
                >
                  {data.titleColored}
                </motion.span>
              </motion.h1>
            )}
            <motion.p
              className="connect__text text"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 1.2 }}
            >
              {data.text}
            </motion.p>
          </div>
          <motion.div
            className="connect__form"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 1.5 }}
          >
            {/* <FormEmail
              className="form"
              source="Connect"
              setIsRequestSent={setIsRequestSent}
              buttonText="Get started"
            /> */}
            <Button 
            className="connect__button"
            onClick={openModal}>
            {data.button.text}
            </Button>
          </motion.div>
        </div>
        <Globe className="globe" /> 
      </div>
    </motion.section>
  );
};

export default Connect;
