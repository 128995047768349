import {
  PopUpProps,
  FormData,
  LatamPopup,
  PopupField,
} from "../../types/types";
import { motion } from "framer-motion";
import api from "../../api/apiContact";
import Button from "../Button/Button";
import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";
import TelInput from "../TelInput/TelInput";

interface FormErrors {
  [key: string]: string;
}

const PopUp: React.FC<PopUpProps> = ({
  className,
  onSubmit,
  setIsOpen,
  setIsRequestSent,
  data,
  locale,
}) => {
  const [formData, setFormData] = useState<any>({
    emailTemplate: "latamPopup",
  });

  const [errors, setErrors] = useState<FormErrors>({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isFormSubmitted) return;
    setIsFormSubmitted(true);

    const isValid = validateFormData(formData);

    if (!isValid) {
      setIsFormSubmitted(false);
      return;
    }

    const { grecaptcha } = window as any;

    grecaptcha.ready(async () => {
      try {
        const token = await grecaptcha.execute(
          "6LcLzzInAAAAAAafjmJZ_5m7AyC8LAPdp2fEDjd2",
          {
            action: "submit",
          }
        );

        onSubmit(formData);
        setIsOpen(false);
        setIsRequestSent(true);

        const response = await api.sendPopup(token, formData);

        if (response) {
          console.log("Email sent successfully!");
        } else {
          console.log("Message sending failed");
        }
      } catch (error) {
        console.log("Request failed", error);
      }
    });
  };

  const validateFormData = (formData: any) => {
    setErrors({});

    if (!popupData?.fields) return;
    const tempErrors = {} as any;

    const fields = popupData.fields;

    for (const field of fields) {
      if (field.isRequired && checkForEmptyString(formData[field.name])) {
        tempErrors[field.name] = "should not be empty";
        continue;
      }

      if (!formData[field.name]) continue;

      let isValid;

      switch (field.type) {
        case "phone":
          isValid = /^\+?[0-9]+$/.test(formData[field.name]);
          break;
        case "email":
          isValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
            formData[field.name]
          );
          break;
        case "business_email":
          const standardEmail =
            /^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn|yandex|bk)\..+)(.+@.+\..+)$/;
          isValid = standardEmail.test(formData[field.name]);
          break;
        case "website":
          const websiteRegex =
            /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(\/[^\s]*)?$/;
          isValid = websiteRegex.test(formData[field.name]);
          break;
        default:
          isValid = true;
      }

      if (!isValid) {
        tempErrors[field.name] = "is not valid";
        continue;
      }
    }

    if (popupData?.privacyPolicy && !formData.privacyPolicy) {
      tempErrors.privacyPolicy = "must be true";
    }

    setErrors(() => ({ ...tempErrors }));

    return !Object.keys(tempErrors).length;
  };

  const checkForEmptyString = (str: string) => {
    return !str.trim().length;
  };

  useEffect(() => {
    setIsFormSubmitted(false);
    setErrors({});
  }, [formData]);

  const [popupData, setPopupData] = useState<LatamPopup>();

  const initFormData = (data: any) => {
    if (data.fields) {
      const newObj = data.fields.reduce((acc: any, field: any) => {
        acc[field.name] = "";
        return acc;
      }, {});

      if (data.subject) {
        newObj.subject = data.subject;
      }

      if (data.privacyPolicy) {
        (newObj as any).privacyPolicy = false;
      }

      setFormData((old: any) => ({ ...old, ...newObj }));
    }
  };

  useEffect(() => {
    initFormData(data);
    setPopupData(data);
  }, []);

  const getField = (field: PopupField) => {
    switch (field.type) {
      case "phone":
        return (
          <TelInput
            placeholder={field.placeholder}
            value={formData[field.name]}
            error={errors[field.name]}
            onChange={(v = "") => setFormData({ ...formData, [field.name]: v })}
          />
        );
      case "text":
      case "website":
        return (
          <div>
            <input
              className={`${className}__input ${
                errors[field.name] ? "error" : ""
              }`}
              type="text"
              value={formData[field.name]}
              placeholder={field.placeholder}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  [field.name]: e.target.value,
                })
              }
            />
            {errors[field.name] && formData[field.name] && (
              <motion.p
                className="field-error"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
              >
                Please provide a valid website URL.
              </motion.p>
            )}
          </div>
        );
      case "email":
        return (
          <input
            className={`${className}__input ${
              errors[field.name] ? "error" : ""
            }`}
            value={formData[field.name]}
            placeholder={field.placeholder}
            onChange={(e) =>
              setFormData({
                ...formData,
                [field.name]: e.target.value,
              })
            }
          />
        );
      case "business_email":
        return (
          <div>
            <input
              className={`${className}__input ${
                errors[field.name] ? "error" : ""
              }`}
              value={formData[field.name]}
              placeholder={field.placeholder}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  [field.name]: e.target.value,
                })
              }
            />
            {errors[field.name] && formData[field.name] && (
              <motion.p
                className="field-error"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
              >
                Please provide your business email address instead of a public
                one.
              </motion.p>
            )}
          </div>
        );
      case "textarea":
        return (
          <textarea
            className={`${className}__textarea ${
              errors[field.name] ? "error" : ""
            }`}
            value={formData[field.name]}
            placeholder={field.placeholder}
            onChange={(e) =>
              setFormData({ ...formData, [field.name]: e.target.value })
            }
          />
        );
    }
  };

  return (
    <>
      <form autoComplete="off" onSubmit={handleSubmit} className={className}>
        {popupData && (
          <React.Fragment>
            <div className={`${className}__item `}>
              <label className="text-large">{popupData.text}</label>
            </div>
            {popupData.fields?.map((field) => (
              <div key={field.id}>{getField(field)}</div>
            ))}
            {popupData.privacyPolicy && (
              <div className={`${className}__item checkbox`}>
                <input
                  id="checkbox"
                  className={`checkbox__input ${
                    errors.privacyPolicy ? "error" : ""
                  }`}
                  type="checkbox"
                  name="privacyPolicy"
                  checked={formData.privacyPolicy}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      privacyPolicy: !formData.privacyPolicy,
                    })
                  }
                />
                <label className={`checkbox__label `} htmlFor="checkbox">
                  <span className="checkbox__text text-normal">
                    {popupData.privacyPolicy.text}
                    {popupData.privacyPolicy.link && (
                      <>
                        &nbsp;
                        <a
                          href={`${popupData.privacyPolicy.link.path}`}
                          rel="noopener noreferrer"
                          target={
                            popupData.privacyPolicy.link.isExternal
                              ? "_blank"
                              : ""
                          }
                          className="address__link"
                        >
                          {popupData.privacyPolicy.link.text}
                        </a>
                      </>
                    )}
                  </span>
                </label>
              </div>
            )}
            <Button type="submit" className="form__button">
              {popupData.button.text}
            </Button>
          </React.Fragment>
        )}
      </form>
      <div className="capchatext">
        <p className="text-micro">Protected by reCAPTCHA</p>
        <a
          className="text-micro"
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy
        </a>
        <a
          className="text-micro"
          target="_blank"
          rel="noopener noreferrer"
          href="https://policies.google.com/terms?hl=en"
        >
          Terms
        </a>
      </div>
    </>
  );
};

export default PopUp;
