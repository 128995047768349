import React, { useEffect, useState, lazy, Suspense, useCallback } from "react";
import {
  AboutPageData,
  FormData,
  LatamPopup as LatamPopupType,
} from "../../types/types";
import api from "../../api/apiContact";

//const Partners = lazy(() => import("../../components/Partners/Partners"));
const Benefits = lazy(() => import("../../components/Benefits/Benefits"));
const Payments = lazy(() => import("../../components/Payments/Payments"));
const Modal = lazy(() => import("../../components/Modal/Modal"));
const Button = lazy(() => import("../../components/Button/Button"));
import LatamPopup from "../../components/LatamPopup/LatamPopup";

const About: React.FC = () => {
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [pageData, setPageData] = useState<AboutPageData>();

  const [popupData, setPopupData] = useState<LatamPopupType>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.getLatamPopupData();
        setPopupData(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleSubmit = useCallback((formData: FormData) => {
    console.log(formData);
    setIsOpen(false);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const res = await api.getAboutPageData();
      setPageData(res.data);
    };

    fetchData();
  }, []);

  //const renderSection = useCallback(
  //  (section: SectionData) => {
  //    const { attributes, id } = section;
  //    const { nameSection } = attributes;
  //    switch (nameSection) {
  //      case "Payments":
  //        const text = sectionsText
  //          .filter((text) => text.name === section.attributes.nameSection)
  //          .map((item) => item.text);
  //        return (
  //          <Payments
  //            key={id}
  //            data={section}
  //            text={text}
  //            openModal={openModal}
  //          />
  //        );
  //      //case "Benefits":
  //      //  return (
  //      //    <Benefits
  //      //      key={section.id}
  //      //      data={section}
  //      //      children="Contact us"
  //      //      openModal={openModal}
  //      //    />
  //      //  );
  //      // case 'Partners':
  //      //   return <Partners key={section.id} data={section} />;
  //      default:
  //        return null;
  //    }
  //  },
  //  [sectionsText, openModal]
  //);

  //const orderedData = [
  //  sections.find((section) => section.attributes.nameSection === "Payments"),
  //  sections.find((section) => section.attributes.nameSection === "Benefits"),
  //  sections.find((section) => section.attributes.nameSection === "Partners"),
  //];

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {pageData && (
        <>
          {pageData.payments && (
            <Payments data={pageData.payments} openModal={openModal} />
          )}
          {pageData.advantages && (
            <Benefits data={pageData.advantages} openModal={openModal} />
          )}
        </>
      )}
      <>
        {isRequestSent ? (
          <Modal
            isOpen={isRequestSent}
            onClose={() => setIsRequestSent(false)}
            className="thank-popup popup"
            title="Thank you!"
          >
            <p className="popup__text text-large ">
              Your request has been successfully sent.
            </p>
            <p className="popup__text text-large ">
              Our expert will contact you soon.
            </p>
            <Button
              className="popup__button"
              type="button"
              onClick={() => setIsRequestSent(false)}
            >
              Close
            </Button>
          </Modal>
        ) : (
          <Modal
            isOpen={isOpen}
            onClose={closeModal}
            className="popup"
            title="Share your request with our sales team"
          >
            <LatamPopup
              className="form-popup"
              data={popupData}
              onSubmit={handleSubmit}
              setIsOpen={setIsOpen}
              setIsRequestSent={setIsRequestSent}
              locale="es-AR"
            />
          </Modal>
        )}
      </>
    </Suspense>
  );
};

export default About;
